import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { debounce } from '../../utils/helpers'
import NavDesktop from '../NavDesktop'
import NavMobile from '../NavMobile'

const StyledHeader = styled.header`
  #nav_desktop {
    display: none;
  }

  @media screen and (min-width: 1080px) {
    #nav_desktop {
      display: block;
    }
    #nav_mobile {
      display: none;
    }
  }
`

interface HeaderProps {
  isHomepage?: boolean
  locale: string
}

const Header = (props: HeaderProps) => {
  const { isHomepage = false, locale } = props
  const [prevScrollPosition, setPrevScrollPosition] = useState(0)

  const [showNav, setShowNav] = useState(true)

  useEffect(() => {
    const handleScroll = debounce(() => {
      const currentScrollPosition = window?.pageYOffset
      setShowNav(
        prevScrollPosition > currentScrollPosition ||
          currentScrollPosition < 50,
      )
      setPrevScrollPosition(currentScrollPosition)
    }, 350)

    window?.addEventListener('scroll', handleScroll)
    return () => {
      window?.removeEventListener('scroll', handleScroll)
    }
  }, [prevScrollPosition])

  const data = useStaticQuery<GatsbyTypes.HeaderQuery>(graphql`
    query Header {
      allContentstackSectionHeader {
        nodes {
          locale
          publish_details {
            locale
          }
          language_selector {
            id
            country
            language
            short_code
          }
          navigation_items {
            id
            title
            text
            is_clickable
            link {
              ...NavigationLinkFragment
            }
            menu_items {
              ...MenuItem
            }
          }
          mobile_contact_cta
          cta_button {
            ... on Contentstack_component_cta_button {
              id
              variant
              link {
                ...NavigationLinkFragment
              }
            }
          }
          logo {
            logo {
              description
              imgixImage {
                gatsbyImageData(
                  width: 212
                  placeholder: BLURRED
                  imgixParams: { q: 90 }
                )
              }
            }
          }
          logo_mobile {
            logo {
              description
              imgixImage {
                gatsbyImageData(width: 32)
              }
            }
          }
        }
      }
      allResourceUrls: allContentstackPageResource {
        nodes {
          pageUrl: url
          locale
          publish_details {
            locale
          }
          resource {
            id
          }
        }
      }
      allProgramUrls: allContentstackPageSalesTrainingProgram {
        nodes {
          pageUrl: url
          locale
          publish_details {
            locale
          }
          program {
            id
          }
        }
      }
      navigationData: allContentstackComponentNavigationItem(
        filter: {
          title: { eq: "Nav Item - Solutions - Variation - April 2023" }
        }
      ) {
        nodes {
          id
          locale
          publish_details {
            locale
          }
          title
          text
          is_clickable
          link {
            ...NavigationLinkFragment
          }
          menu_items {
            ...MenuItem
          }
        }
      }
    }

    fragment MenuItem on Contentstack_topic_training_programContentstack_topic_resourceContentstack_topic_linkContentstack_topic_eventContentstack_component_navigation_itemContentstack_component_nav_card_collection_Union {
      __typename

      ... on Contentstack_component_navigation_item {
        id
        is_clickable
        link {
          ...NavigationLinkFragment
        }
        text
        menu_items {
          ... on Contentstack_topic_link {
            ...NavigationLinkFragment
          }
        }
      }
      ... on Contentstack_topic_link {
        ...NavigationLinkFragment
      }
      ... on Contentstack_component_nav_card_collection {
        id
        title
        headline
        internal {
          type
        }
        link {
          ...NavigationLinkFragment
        }
        cards {
          ... on Contentstack_topic_resource {
            id
            headline
            title
            internal {
              type
            }
            feature_image {
              description
              imgixImage {
                gatsbyImageData(width: 200, imgixParams: { q: 70 })
              }
            }
          }
          ... on Contentstack_topic_event {
            id
            headline
            internal {
              type
            }
            id
            event_start
            event_page {
              ...NavigationLinkFragment
            }
            feature_image {
              description
              imgixImage {
                gatsbyImageData(width: 200, imgixParams: { q: 70 })
              }
            }
          }
          ... on Contentstack_topic_training_program {
            id
            title
            headline
            internal {
              type
            }
            feature_image {
              description
              imgixImage {
                gatsbyImageData(width: 200, imgixParams: { q: 70 })
              }
            }
          }
        }
      }
    }
  `)

  const localizedNavigation = data.allContentstackSectionHeader.nodes.filter(
    (node) => node.publish_details?.locale === locale,
  )?.[0]

  const navItems = data?.navigationData?.nodes?.find(
    (node) => node?.publish_details?.locale === locale,
  )

  const localizedNavigationItems = {
    ...localizedNavigation,
    navigation_items: navItems
      ? [
          navItems,
          ...localizedNavigation.navigation_items.filter(
            ({ text }) => text !== 'Solutions',
          ),
        ]
      : localizedNavigation.navigation_items,
  }

  const localizedResourceUrls = data.allResourceUrls.nodes.filter(
    (node) => node.publish_details?.locale === locale,
  )
  const localizedProgramUrls = data.allProgramUrls.nodes.filter(
    (node) => node.publish_details?.locale === locale,
  )
  const localizedData = {
    allContentstackSectionHeader: {
      nodes: [localizedNavigationItems],
    },
    allResourceUrls: {
      nodes: localizedResourceUrls,
    },
    allProgramUrls: {
      nodes: localizedProgramUrls,
    },
  }
  const currentLocale =
    localizedData?.allContentstackSectionHeader?.nodes?.[0]?.publish_details
      ?.locale

  return (
    <StyledHeader>
      <div id="no_mobile">
        <NavMobile
          data={localizedData}
          locale={currentLocale || undefined}
          showNav={showNav}
        />
      </div>
      <div id="nav_desktop">
        <NavDesktop
          data={localizedData}
          isHomepage={isHomepage}
          locale={currentLocale || undefined}
          showNav={showNav}
        />
      </div>
    </StyledHeader>
  )
}

export default Header
