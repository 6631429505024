import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

import Link from '../Link'

interface LogoProps {
  logoData: any
  className?: string
}

const Logo = (props: LogoProps) => {
  const { logoData, className = '' } = props
  const logoImage = getImage(logoData?.logo?.imgixImage)

  return (
    <div className={className}>
      <Link directUrl="/">
        <>
          {logoImage ? (
            <GatsbyImage
              alt={logoData.alt_text || 'Richardson logo'}
              backgroundColor="transparent"
              image={logoImage}
            />
          ) : null}
        </>
      </Link>
    </div>
  )
}

export default Logo
