import React from 'react'
import styled from 'styled-components'

import linkTriangle from '../../images/linkTriangle.svg'
import { rspTheme } from '../../styles/rspTheme'
import Link from '../Link'

interface TopLevelLinkProps {
  item: any
  setCurrentSubNav: any
  currentSubNav: string
}

const StyledLink = styled.span`
  position: relative;

  &.topLevelHover {
    background-color: ${rspTheme.palette.primary.background};

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 5px;
      height: 9px;
      right: 9px;
      bottom: calc(50% - 6px);
      background-image: url(${linkTriangle});
    }
  }

  a {
    display: inline-block;
    padding: 8px 22px;
    border-radius: 4px;
    color: ${rspTheme.palette.primary.main};
    transition: background-color 0.3s ease-in-out;
    text-decoration: none;
    text-align: center;
    position: relative;
  }
`

const StyledButton = styled.button`
  display: inline-block;
  padding: 8px 20px;
  border-radius: 4px;
  border: none;
  background-color: transparent;
  color: ${rspTheme.palette.primary.main};
  transition: background-color 0.3s ease-in-out;
  text-decoration: none;
  text-align: center;

  &.topLevelHover {
    background-color: ${rspTheme.palette.primary.background};
  }
`

const TopLevelLink = (props: TopLevelLinkProps) => {
  const { item, setCurrentSubNav, currentSubNav } = props
  const linkTarget = item.link?.[0]?.internal_page?.[0]
    ? item.link?.[0]?.internal_page?.[0]?.url
    : item.link?.[0]?.internal_overview_page?.[0]?.url || item.link?.[0]?.internal_overview_page?.[0]?.pageUrl

  if (item?.is_clickable === 'True') {
    return (
      <StyledLink className={currentSubNav === item.id ? 'topLevelHover' : ''}>
        <Link
          directUrl={linkTarget || '/'}
          onBlur={() => setCurrentSubNav('')}
          onFocus={() => setCurrentSubNav(item.id)}
          onMouseEnter={() => setCurrentSubNav(item.id)}
        >
          {item.link?.[0]?.text}
        </Link>
      </StyledLink>
    )
  }

  return (
    <StyledButton
      className={currentSubNav === item.id ? 'topLevelHover' : ''}
      onBlur={() => setCurrentSubNav('')}
      onClick={() => {}}
      onFocus={() => setCurrentSubNav(item.id)}
      onMouseEnter={() => setCurrentSubNav(item.id)}
      type="button"
    >
      {item?.text}
    </StyledButton>
  )
}

export default TopLevelLink
