import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Popper from '@material-ui/core/Popper'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import navigatorLanguages from 'navigator-languages'
import React, { useEffect, useState } from 'react'

import { Locale, useLocale } from '../LocaleContext'
import { Container } from './styles'

interface LanguageSelectorProps {
  currentLocale?: string
  setShowMenu?: any
}

const LanguageSelector = (props: LanguageSelectorProps) => {
  const data = useStaticQuery<GatsbyTypes.AllLocalesQuery>(graphql`
    query AllLocales {
      allContentstackSectionHeader {
        nodes {
          id
          publish_details {
            locale
          }
          language_selector {
            id
            country
            language
            short_code
            region_code
          }
        }
      }
    }
  `)
  // currentPageLocale comes to us via <Layout /> – it's the current page's publish_details.locale
  const { currentLocale: currentPageLocale } = props
  const [currentPath, setCurrentPath] = useState(``)
  // grab any search params, if present
  const currentSearchParams =
    typeof window !== 'undefined' ? window.location.search : ''
  const [anchorEl, setAnchorEl] = useState(null)
  // siteLocale is the current locale set in the sitewide Locale context
  const { setSiteLocale } = useLocale()
  const userLanguagePrefs = navigatorLanguages()
  const isMobile = useMediaQuery('(max-width:980px)')

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const currentLocaleMenu = data?.allContentstackSectionHeader?.nodes?.filter(
    (node) => node?.publish_details?.locale === currentPageLocale,
  )?.[0]

  const defaultLocaleState = currentLocaleMenu?.language_selector?.find(
    (locale) => locale?.short_code?.toLowerCase() === currentPageLocale,
  )

  const [selectedLocale, setSelectedLocale] = useState<Locale | undefined>(
    defaultLocaleState,
  )

  useEffect(() => {
    // get and set menuItem associated with currentLocale
    const currentLocaleMenuItem = currentLocaleMenu?.language_selector?.find(
      (locale: any) => locale?.short_code?.toLowerCase() === currentPageLocale,
    )

    // determine and set the current url without language shortCode
    const currentNonLocalizedUrl = location?.pathname?.replace(
      `/${currentPageLocale}`,
      ``,
    )
    location && setCurrentPath(currentNonLocalizedUrl)

    const storedLocalePref = localStorage?.getItem('rspLocaleSetting')
    if (storedLocalePref) {
      // has the user previously selected a preference from the region switcher? If so, respect that
      const localeMenuItemFromStoredPref =
        currentLocaleMenu?.language_selector?.find(
          (locale: any) => locale?.region_code === storedLocalePref,
        )
      setSelectedLocale(localeMenuItemFromStoredPref)
      setSiteLocale({
        ...localeMenuItemFromStoredPref,
        locale: localeMenuItemFromStoredPref?.short_code?.toLowerCase(),
      })
    } else if (
      location &&
      !storedLocalePref &&
      currentNonLocalizedUrl === '/'
    ) {
      // if the user has not selected a region and we're on the home page,
      // check their browser lang preference and set the locale context to match
      // (If we're not on the homepage, the user may have navigated there directly and we should
      // respect the url)
      const localeFromBrowserLangPrefs =
        currentLocaleMenu?.language_selector?.find(
          (locale: any) =>
            userLanguagePrefs?.[0]?.toLowerCase() ===
            locale?.short_code?.toLowerCase(),
        )
      setSiteLocale({
        ...localeFromBrowserLangPrefs,
        locale: localeFromBrowserLangPrefs?.short_code?.toLowerCase(),
      })
      setSelectedLocale(localeFromBrowserLangPrefs)
    } else {
      setSelectedLocale(currentLocaleMenuItem)
    }
  }, [])

  // navigate to the appropriate path if the selected locale changes
  // useEffect(() => {
  //   if (currentPath) {
  //     const targetUrl = selectedLocale?.short_code?.toLowerCase() === "en-us" ?
  //       currentPath :
  //       `/${selectedLocale?.short_code?.toLowerCase()}${currentPath}`;
  //     navigate(targetUrl);
  //   }
  // }, [selectedLocale]);

  const onClickLocale = (event: any, locale: any, targetUrl: string) => {
    event?.preventDefault()
    setSiteLocale({ ...locale, locale: locale?.short_code?.toLowerCase() })
    setSelectedLocale(locale)
    localStorage?.setItem('rspLocaleSetting', locale?.region_code)
    handleClose()
    props?.setShowMenu && props?.setShowMenu(false)
    navigate(targetUrl)
  }

  return (
    <Container>
      <button
        aria-describedby={`popper-${currentLocaleMenu?.id}`}
        className={anchorEl ? 'toggleButton open' : 'toggleButton'}
        onClick={handleClick}
        type="button"
      >
        {/* just show the currently-selected region code in the unclicked state */}
        {currentPageLocale ? (
          <Typography className="leading-none" variant="caption">
            {selectedLocale?.region_code}
          </Typography>
        ) : null}
        <span className="arrow">
          {anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </span>
      </button>

      <Popper
        anchorEl={anchorEl}
        disablePortal
        id={`popper-${currentLocaleMenu?.id}`}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
        }}
        open={Boolean(anchorEl)}
        placement={isMobile ? 'bottom' : 'bottom-end'}
        style={{ zIndex: 9999, width: 'auto' }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            {currentLocaleMenu?.language_selector ? (
              <ul key={`menu-${currentLocaleMenu.id}`}>
                {currentLocaleMenu?.language_selector?.map((locale) => {
                  let targetUrl =
                    locale?.short_code?.toLowerCase() === 'en-us'
                      ? currentPath
                      : typeof locale?.short_code === 'undefined'
                      ? currentPath
                      : `/${locale?.short_code?.toLowerCase()}${currentPath}`

                  // enforce trailing slash in url if it's not already present
                  if (targetUrl.slice(-1) !== '/') {
                    targetUrl += '/'
                  }

                  // add back search params, if any were present
                  if (currentSearchParams) {
                    targetUrl += currentSearchParams
                  }

                  if (locale?.region_code === 'CN') {
                    return (
                      <li className="mt-edge" key={locale.id}>
                        <a href="https://www.richardsoncn.com">
                          <Typography variant="caption">China (CN)</Typography>
                        </a>
                      </li>
                    )
                  }

                  return (
                    <li className="mt-edge" key={`menu-item-${locale?.id}`}>
                      <a
                        href={targetUrl}
                        onClick={(event: any) =>
                          onClickLocale(event, locale, targetUrl)
                        }
                      >
                        <Typography variant="caption">
                          {`${locale?.country} (${locale?.region_code})`}
                        </Typography>
                      </a>
                    </li>
                  )
                })}
              </ul>
            ) : null}
          </div>
        </ClickAwayListener>
      </Popper>
    </Container>
  )
}
export default LanguageSelector
