import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;

  .toggleButton {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    color: ${rspTheme.palette.primary.main};
    border-radius: 8px;
    padding: 9px 8px 9px 13px;
    font-size: 1.4rem;
    line-height: 1;

    &.open {
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
      border-bottom: 1px solid #bdbdbd;
    }

    .arrow {
      margin-left: 2px;
      margin-top: 1px;
    }
  }

  ul {
    list-style: none;
    padding: 12px 0;
    margin: 0;
    font-size: 1.4rem;
    line-height: 1;
    background-color: #ffffff;
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    width: 295px;

    li {
      a {
        color: inherit;
        text-decoration: none;
        padding: 12px 14px 12px 14px;
        border-radius: 0 0 8px 8px;
        display: flex;
        justify-content: center;

        &:hover,
        &:focus-within {
          background-color: ${rspTheme.palette.primary.background};
        }
      }
    }
  }
`
