import React from 'react'
import styled from 'styled-components'

import Link from '../Link'
import { linkStyling } from './VerticalLink'

interface LinkGridProps {
  menuItems: any
  parentItem: string
  setCurrentSubNav: any
}

const LinkGridWrapper = styled.ul`
  margin: 0 auto;
  padding: 0;
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(4, minmax(204px, max-content));
  grid-column-gap: 30px;
  grid-row-gap: 12px;
  justify-items: start;
  grid-auto-flow: row;
  list-style: none;

  li {
    min-width: 200px;

    a {
      font-weight: 300;
      ${linkStyling};
    }
  }
`

const LinkGrid = (props: LinkGridProps) => {
  const { menuItems, parentItem, setCurrentSubNav } = props
  return (
    <LinkGridWrapper>
      {menuItems.map((menuItem: any) => (
        <li key={menuItem.id}>
          <Link
            link={menuItem}
            onFocus={() => setCurrentSubNav(parentItem)}
            onMouseEnter={() => setCurrentSubNav(parentItem)}
          >
            {menuItem.text}
          </Link>
        </li>
      ))}
    </LinkGridWrapper>
  )
}

export default LinkGrid
