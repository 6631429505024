import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

interface NavProps {
  showNav: boolean
}

export const NavContainer = styled.div<NavProps>`
  position: fixed;
  top: ${(props) => (props.showNav ? '0' : `-${rspTheme.navHeight}`)};
  left: 0;
  right: 0;
  color: ${rspTheme.palette.primary.main};
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 100;
  background-color: #ffffff;

  &.homepage {
    background-color: #ffffff;
  }
`

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;

  & .mainNavLogo {
    flex-shrink: 1;
  }

  & .subNav {
    background-color: #ffffff;
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    background-color: #ffffff;
    border-bottom: 1px solid ${rspTheme.palette.primary.border};
    top: 0;
    left: 0;
    width: 100%;
    height: calc(${rspTheme.navHeight} + 1px);
    z-index: -5;
  }
`

export const TopLevelNav = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  list-style: none;
  margin: 0;
  padding: 0;
  min-height: ${rspTheme.navHeight};
  font-weight: ${rspTheme.typography.mainNav.fontWeight};
  font-size: ${rspTheme.typography.mainNav.fontSize};
`

export const TopLevelNavItem = styled.li`
  display: flex;
  align-items: center;

  > button,
  > a {
    white-space: nowrap;
  }

  &:not(:first-of-type) {
    margin-inline-start: 16px;

    @media (max-width: 1100px) {
      margin-inline-start: 0;
    }
  }
`

export const SubNav = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: -10;
  transform: translateY(calc(-100% - ${rspTheme.navHeight}));
  transform-origin: top;
  transition: transform 0.25s ease-in-out;
  /* border-top: 1px solid ${rspTheme.palette.primary.dark}; */
  padding: 32px 0 40px;

  &.showSubNav {
    transform: translateY(0);
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.24);
  }
`
