import React from 'react'
import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'
import Link from '../Link'
import FeatureCard from './FeatureCard'
import VerticalLink from './VerticalLink'

interface SolutionsSubNavProps {
  menuItems: GatsbyTypes.MenuItemFragment[]
  setCurrentSubNav: any
  parentItem: string
  allProgramUrls?: {
    url?: string
    pageUrl?: string
    resource: {
      id: string
    }[]
  }[]
  allResourceUrls?: {
    pageUrl: string
    resource: {
      id: string
    }[]
  }[]
}

const SolutionsSubNavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`

const FeatureCardContainer = styled.div`
  margin-left: 40px;
`

const FeatureCardHeadline = styled.p`
  color: ${rspTheme.palette.text.disabled};
  font-weight: 400;
  margin-bottom: 18px;
`

const TwoUp = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  > span + span {
    margin-left: 16px;
  }

  a {
    color: inherit;
    text-decoration-color: transparent;
    transition: 0.2s ease-in-out;

    &:hover,
    &:focus-visible {
      text-decoration-color: inherit;
    }
  }
`

const SolutionsSubNav = ({
  allProgramUrls,
  allResourceUrls,
  menuItems,
  setCurrentSubNav,
  parentItem,
}: SolutionsSubNavProps) => (
  <SolutionsSubNavWrapper>
    {menuItems.map((menuItem) => (
      <React.Fragment key={menuItem.id}>
        {menuItem.__typename === 'Contentstack_component_navigation_item' ? (
          <VerticalLink
            menuItem={menuItem}
            parentItem={parentItem}
            setCurrentSubNav={setCurrentSubNav}
          />
        ) : (
          <FeatureCardContainer>
            <TwoUp>
              <FeatureCardHeadline>{menuItem.headline}</FeatureCardHeadline>
              {menuItem.link?.[0] ? (
                <FeatureCardHeadline>
                  <Link
                    link={menuItem.link?.[0]}
                    onFocus={() => setCurrentSubNav(parentItem)}
                    onMouseEnter={() => setCurrentSubNav(parentItem)}
                  >
                    {menuItem.link?.[0]?.text}
                  </Link>
                </FeatureCardHeadline>
              ) : null}
            </TwoUp>
            <TwoUp>
              {menuItem.cards?.map((card: any) => (
                <FeatureCard
                  card={card}
                  key={card.id}
                  linkTarget={
                    card.event_page?.[0]?.external_url ||
                    allProgramUrls?.filter(
                      (program: any) => program.program?.[0]?.id === card.id,
                    )?.[0]?.pageUrl ||
                    allResourceUrls?.filter(
                      (resource: any) => resource.resource?.[0]?.id === card.id,
                    )?.[0]?.pageUrl
                  }
                  parentItem={parentItem}
                  setCurrentSubNav={setCurrentSubNav}
                />
              ))}
            </TwoUp>
          </FeatureCardContainer>
        )}
      </React.Fragment>
    ))}
  </SolutionsSubNavWrapper>
)

export default SolutionsSubNav
