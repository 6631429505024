import React, { useState } from 'react'

import LocaleSwitcher from '../LocaleSwitcher'
import LinkCTA from '../UI/LinkCTA'
import Wrapper from '../UI/Wrapper'
import LinkGrid from './LinkGrid'
import Logo from './Logo'
import SolutionsSubNav from './SolutionsSubNav'
import {
  Nav,
  NavContainer,
  SubNav,
  TopLevelNav,
  TopLevelNavItem,
} from './styles'
import TopLevelLink from './TopLevelLink'

interface NavDesktopProps {
  data: any
  isHomepage: boolean
  locale?: string
  showNav: boolean
}

const NavDesktop = (props: NavDesktopProps) => {
  const { data, isHomepage, locale = 'en-us', showNav = true } = props

  const items = data.allContentstackSectionHeader?.nodes?.[0]?.navigation_items
  const navigationItems = Array.isArray(items) ? items : [items]
  const ctaContent =
    data.allContentstackSectionHeader?.nodes?.[0]?.cta_button?.[0]
  const logoData = data?.allContentstackSectionHeader?.nodes?.[0]?.logo[0]
  const allResourceUrls = data.allResourceUrls.nodes
  const allProgramUrls = data.allProgramUrls.nodes

  const [currentSubNav, setCurrentSubNav] = useState('')

  return (
    <NavContainer className={isHomepage ? 'homepage' : ''} showNav={showNav}>
      <Wrapper>
        <Nav className={isHomepage ? 'homepage' : ''} data-testid="desktop-nav">
          <Logo className="mainNavLogo" logoData={logoData} />
          <TopLevelNav>
            {navigationItems.map((item: any) => (
              <TopLevelNavItem
                key={item.id}
                onMouseEnter={() => setCurrentSubNav(item.id)}
                onMouseLeave={() => setCurrentSubNav('')}
              >
                <TopLevelLink
                  currentSubNav={currentSubNav}
                  item={item}
                  setCurrentSubNav={setCurrentSubNav}
                />
                {item.menu_items.length > 0 ? (
                  <SubNav
                    className={
                      currentSubNav === item.id ? 'showSubNav subNav' : 'subNav'
                    }
                  >
                    <Wrapper>
                      {item.menu_items[0]?.menu_items ? (
                        <SolutionsSubNav
                          allProgramUrls={allProgramUrls}
                          allResourceUrls={allResourceUrls}
                          menuItems={item.menu_items}
                          parentItem={item.id}
                          setCurrentSubNav={setCurrentSubNav}
                        />
                      ) : (
                        <LinkGrid
                          menuItems={item.menu_items}
                          parentItem={item.id}
                          setCurrentSubNav={setCurrentSubNav}
                        />
                      )}
                    </Wrapper>
                  </SubNav>
                ) : null}
              </TopLevelNavItem>
            ))}
            <TopLevelNavItem style={{ marginInlineStart: '24px' }}>
              <LinkCTA link={ctaContent?.link[0]} variant="primary">
                {ctaContent?.link[0]?.text}
              </LinkCTA>
            </TopLevelNavItem>
            <LocaleSwitcher currentLocale={locale} />
          </TopLevelNav>
        </Nav>
      </Wrapper>
    </NavContainer>
  )
}

export default NavDesktop
