import Drawer from '@material-ui/core/Drawer'
import ListItem from '@material-ui/core/ListItem'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import React, { useState } from 'react'

import Link from '../Link'
import { StyledList } from './index'
import { BackButton, ChevronButton, NavSubheadText, NavText } from './styles'

interface SubNavProps {
  parentTitle: string
  menuItems: GatsbyTypes.MenuItemFragment[]
  setCurrentSubNav: any
  classes: any
}

const SubNav = (props: SubNavProps) => {
  const { parentTitle, menuItems, setCurrentSubNav, classes } = props
  const [subSubNav, setSubSubNav] = useState('')

  const navigationItemTypes = [
    'Contentstack_topic_link',
    'Contentstack_component_navigation_item',
  ]

  return (
    <StyledList
      style={{
        boxShadow: `inset 20px 0px 0px 0px rgba(0,0,0,0.04)`,
        paddingTop: `0`,
        height: `100%`,
      }}
    >
      <ListItem
        style={{
          borderBottom: `1px solid #e0e0e0`,
          alignItems: `center`,
          padding: `20px 18px`,
          backgroundColor: `#fff`,
        }}
      >
        <BackButton onClick={() => setCurrentSubNav('')}>
          <ChevronLeftIcon /> Back
        </BackButton>
      </ListItem>
      <ListItem style={{ paddingLeft: `40px` }}>
        <NavSubheadText>{parentTitle}</NavSubheadText>
      </ListItem>
      {menuItems.map((menuItem) => (
        <React.Fragment key={menuItem.id}>
          {navigationItemTypes.includes(menuItem.__typename) ? (
            <ListItem
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: `40px`,
              }}
            >
              <NavText>
                {menuItem.is_clickable === 'True' || menuItem.links_to_ ? (
                  <Link link={menuItem.link?.[0] || menuItem}>
                    {menuItem.text ?? menuItem.link?.[0]?.text}
                  </Link>
                ) : (
                  <>{menuItem.text}</>
                )}
              </NavText>
              {menuItem?.menu_items?.length ? (
                <>
                  <ChevronButton onClick={() => setSubSubNav(menuItem.text)}>
                    <ChevronRightIcon />
                  </ChevronButton>
                  <Drawer
                    anchor="right"
                    classes={{ paperAnchorRight: classes.drawerPaper }}
                    onClose={() => {
                      setSubSubNav('')
                    }}
                    open={subSubNav === menuItem.text}
                  >
                    <StyledList
                      style={{
                        boxShadow: `inset 40px 0px 0px 0px rgba(0,0,0,0.04), inset 20px 0px 0px 0px rgba(0,0,0,0.04)`,
                        paddingTop: `0`,
                        height: `100%`,
                      }}
                    >
                      <ListItem
                        style={{
                          borderBottom: `1px solid #e0e0e0`,
                          alignItems: `center`,
                          padding: `20px 18px`,
                          backgroundColor: `#fff`,
                        }}
                      >
                        <BackButton onClick={() => setSubSubNav('')}>
                          <ChevronLeftIcon /> {parentTitle}
                        </BackButton>
                      </ListItem>
                      <ListItem style={{ paddingLeft: `60px` }}>
                        <NavSubheadText>{menuItem.text}</NavSubheadText>
                      </ListItem>
                      {menuItem?.menu_items?.map((item: any) => (
                        <React.Fragment key={item.id}>
                          {item.text ? (
                            <ListItem style={{ paddingLeft: `60px` }}>
                              <NavText>
                                <Link link={item}>{item.text}</Link>
                              </NavText>
                            </ListItem>
                          ) : null}
                        </React.Fragment>
                      ))}
                    </StyledList>
                  </Drawer>
                </>
              ) : null}
            </ListItem>
          ) : null}
        </React.Fragment>
      ))}
    </StyledList>
  )
}

export default SubNav
