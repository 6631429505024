import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

interface NavProps {
  showNav: boolean
}

export const NavContainer = styled.div<NavProps>`
  position: fixed;
  top: ${(props) => (props.showNav ? '0' : `-60px`)};
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  color: ${rspTheme.palette.primary.main};
  border-bottom: 1px solid ${rspTheme.palette.primary.border};
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 100;
`

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SimpleButton = styled.button`
  appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  color: inherit;

  path {
    fill: inherit;
  }

  &:hover,
  &:focus {
    color: ${rspTheme.palette.primary.main};

    path {
      fill: ${rspTheme.palette.primary.main};
    }
  }

  &:focus {
    outline: 1px solid ${rspTheme.palette.primary.main};
  }
`

export const ChevronButton = styled(SimpleButton)`
  border-bottom: 1px solid #e0e0e0;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 39px;
  padding-top: 8px;

  path {
    fill: ${rspTheme.palette.primary.main};
  }
`

export const BackButton = styled(ChevronButton)`
  border-bottom: none;
  margin-left: 0;
  justify-content: flex-start;
  color: ${rspTheme.palette.primary.main};

  svg {
    margin-right: 4px;
    margin-left: -8px;
  }
`

export const NavText = styled.span`
  display: inline-block;
  padding: 14px 0 10px;
  border-bottom: 1px solid #e0e0e0;
  flex-grow: 1;
  color: ${rspTheme.palette.primary.main};
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.5;

  a {
    text-decoration: none;
    color: inherit;
  }
`

export const NavSubheadText = styled.span`
  display: inline-block;
  padding: 20px 0;
  color: #616161;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.5;
`

export const ContactUsItem = styled.li`
  background-color: ${rspTheme.palette.secondary.background};
  padding: 48px 20px 52px;
  text-align: center;
  margin-top: auto;
`

export const PhoneContainer = styled.div`
  color: ${rspTheme.palette.primary.main};
  font-size: 2.2rem;
  /* font-family: 'Open Sans', Helvetica, Arial, sans-serif; */
  font-weight: 300;
  line-height: 1.19;
  letter-spacing: 0.25px;
  margin-top: 28px;

  a {
    color: inherit;
    text-decoration-color: transparent;
    transition: text-decoration-color 0.2s ease-in-out;

    &:hover,
    &:focus {
      text-decoration-color: inherit;
    }
  }
`
