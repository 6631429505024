import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'

import linkTriangle from '../../images/linkTriangle.svg'
import { rspTheme } from '../../styles/rspTheme'
import Link from '../Link'

interface FeatureCardProps {
  card: any
  parentItem: string
  setCurrentSubNav: any
  linkTarget?: string
}

const CardWrapper = styled.span`
  a {
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-decoration: none;
    transition: box-shadow 0.3s ease-in-out;
    width: 200px;
    min-height: 280px;
    flex-shrink: 0;

    &:hover,
    &:focus {
      outline: 0px;
      box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.16);
    }

    &:hover .featureText::after,
    &:focus .featureText::after {
      content: '';
      position: relative;
      display: inline-block;
      width: 5px;
      height: 9px;
      right: -8px;
      bottom: 0;
      background-image: url(${linkTriangle});
    }
  }
`

const ContentBox = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 167px;

  .featureText {
    color: ${rspTheme.palette.primary.main};
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 1.5;
  }

  .cardType {
    color: ${rspTheme.palette.text.disabled};
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: -0.2px;
    line-height: 1.5;
    margin-top: auto;
  }
`

const ImageBox = styled.div`
  background-color: ${rspTheme.palette.primary.main};
  color: #fff;
  height: 120px;
  pointer-events: none;

  .gatsby-image-wrapper {
    width: 100%;
    height: 120px;
  }
`

const FeatureCard = (props: FeatureCardProps) => {
  const { card, linkTarget, parentItem, setCurrentSubNav } = props
  const thumbnailImage = getImage(card?.feature_image?.imgixImage)
  const altText = card?.feature_image?.description || ''

  const cardTypes = {
    Contentstack_topic_resource: 'Resource',
    Contentstack_topic_event: 'Event',
    Contentstack_topic_training_program: 'Program',
  }

  const CardContent = () => (
    <>
      <ImageBox>
        {thumbnailImage ? (
          <GatsbyImage alt={altText} image={thumbnailImage} />
        ) : null}
      </ImageBox>
      <ContentBox>
        {card.description ? (
          <p className="featureText">{card.description?.subheading}</p>
        ) : (
          <>
            <p className="featureText">
              {card.header?.subheading || card.headline}
            </p>
            <p className="cardType">{cardTypes[card.internal.type]}</p>
          </>
        )}
      </ContentBox>
    </>
  )

  if (linkTarget?.startsWith('http')) {
    return (
      <CardWrapper>
        <a
          href={linkTarget || '/'}
          onBlur={() => setCurrentSubNav('')}
          onFocus={() => setCurrentSubNav(parentItem)}
          onMouseEnter={() => setCurrentSubNav(parentItem)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <CardContent />
        </a>
      </CardWrapper>
    )
  }

  return (
    <CardWrapper>
      <Link
        directUrl={linkTarget || '/'}
        onBlur={() => setCurrentSubNav('')}
        onFocus={() => setCurrentSubNav(parentItem)}
        onMouseEnter={() => setCurrentSubNav(parentItem)}
      >
        <CardContent />
      </Link>
    </CardWrapper>
  )
}

export default FeatureCard
