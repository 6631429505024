import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { createStyles, makeStyles, styled } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CloseIcon from '@material-ui/icons/Close'
import Menu from '@material-ui/icons/Menu'
import parse from 'html-react-parser'
import React, { useState } from 'react'

import Link from '../Link'
import LocaleSwitcher from '../LocaleSwitcher'
import Logo from '../NavDesktop/Logo'
import LinkCTA from '../UI/LinkCTA'
import Wrapper from '../UI/Wrapper'
import {
  ChevronButton,
  ContactUsItem,
  Nav,
  NavContainer,
  NavText,
  PhoneContainer,
  SimpleButton,
} from './styles'
import SubNav from './SubNav'

interface NavMobileProps {
  data: any
  showNav: boolean
  locale?: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    drawerPaper: {
      top: `60px`,
    },
  }),
)

export const StyledList = styled(List)({
  width: `100vw`,
  maxWidth: `360px`,
  paddingBottom: `0`,
  height: `calc(100% - 60px)`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
})

const NavMobile = (props: NavMobileProps) => {
  const { data, showNav = true, locale = 'en-us' } = props

  const items = data.allContentstackSectionHeader?.nodes?.[0]?.navigation_items
  const navigationItems = Array.isArray(items) ? items : [items]
  const ctaContent =
    data.allContentstackSectionHeader?.nodes?.[0]?.cta_button?.[0]
  const logoData =
    data?.allContentstackSectionHeader?.nodes?.[0]?.logo_mobile?.[0]
  const contactCTAText =
    data.allContentstackSectionHeader?.nodes?.[0]?.mobile_contact_cta

  const [showMenu, setShowMenu] = useState(false)
  const [currentSubNav, setCurrentSubNav] = useState('')

  const classes = useStyles()

  return (
    <NavContainer showNav={showNav}>
      <Wrapper>
        <Nav data-testid="mobile-nav">
          <Logo logoData={logoData} />
          <List style={{ padding: '0' }}>
            <ListItem style={{ padding: '0' }}>
              <SimpleButton onClick={() => setShowMenu(!showMenu)}>
                {showMenu ? <CloseIcon /> : <Menu />}
                <span className="sr-only">Main navigation</span>
              </SimpleButton>
              <Drawer
                anchor="right"
                classes={{
                  paperAnchorRight: classes.drawerPaper,
                }}
                data-testid="mobile-menu"
                onClose={() => setShowMenu(false)}
                open={showMenu}
              >
                <StyledList>
                  {navigationItems.map((item: any) => (
                    <ListItem key={item.id} style={{ padding: '0 20px' }}>
                      <NavText>
                        {item.is_clickable === 'True' ? (
                          <Link link={item.link?.[0]}>
                            {item.link?.[0]?.text}
                          </Link>
                        ) : (
                          <>{item.text}</>
                        )}
                      </NavText>
                      {item.menu_items.length > 0 && (
                        <>
                          <ChevronButton
                            onClick={() => setCurrentSubNav(item.text)}
                          >
                            <ChevronRightIcon />
                          </ChevronButton>
                          <Drawer
                            anchor="right"
                            classes={{
                              paperAnchorRight: classes.drawerPaper,
                            }}
                            onClose={() => {
                              setCurrentSubNav('')
                            }}
                            open={currentSubNav === item.text}
                          >
                            <SubNav
                              classes={classes}
                              menuItems={item.menu_items}
                              parentTitle={item.text}
                              setCurrentSubNav={setCurrentSubNav}
                            />
                          </Drawer>
                        </>
                      )}
                    </ListItem>
                  ))}
                  <Grid
                    container
                    justifyContent="center"
                    style={{ marginTop: '22px' }}
                  >
                    <LocaleSwitcher
                      currentLocale={locale}
                      setShowMenu={setShowMenu}
                    />
                  </Grid>
                  <ContactUsItem>
                    <LinkCTA link={ctaContent?.link?.[0]}>
                      {ctaContent?.link?.[0]?.text}
                    </LinkCTA>
                    <PhoneContainer>{parse(contactCTAText)}</PhoneContainer>
                  </ContactUsItem>
                </StyledList>
              </Drawer>
            </ListItem>
          </List>
        </Nav>
      </Wrapper>
    </NavContainer>
  )
}

export default NavMobile
